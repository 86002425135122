<template>
    <div class="view pa24">
        <div class="flex-a-b-c">
            <el-button type="primary" @click="searchFun">刷新</el-button>
            <div class="d-flex">
                <div class="message mr10">
                    <el-button type="primary" icon="el-icon-message" @click="showApplyList()"></el-button>
                    <i class="d" v-if="statusNew"></i>
                </div>
                <el-button type="primary" @click="showAddModel()">申请添加合作伙伴</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="name" width="150" align="center" label="LOGO" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.showLogo" style="width: 100px; height: 100px;margin-bottom:-10px"
                            :src="scope.row.showLogo">
                        </el-image>
                        <p v-else
                            style="width: 100px; height: 100px;line-height: 100px;font-size:12px;margin:0 auto;background: #eee;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="targetName" align="center" label="公司名称" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>{{ scope.row.showName }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" width="150" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 0" type="info">{{ statusData[scope.row.status] }}</el-tag>
                        <el-tag v-else-if="scope.row.status == 1">{{ statusData[scope.row.status] }}</el-tag>
                        <el-tag v-else type="danger">{{ statusData[scope.row.status] }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" align="center" label="通过时间" show-overflow-tooltip />
                <!-- <el-table-column align="center" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button class="mr10" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="delcompany(scope.row)"
                            icon="el-icon-info" icon-color="red" title="确定要移除这个合作伙伴？">
                            <el-button slot="reference" style="color: #F56C6C" type="text">删除</el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column> -->
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="550px" center>
            <el-form :model="formData" :rules="rules" ref="formData">
                <el-form-item prop="targetId" class="targetContent">
                    <el-select v-model="formData.targetId" class="targetInput" filterable remote size="medium"
                        placeholder="输入公司名称，选择合作伙伴" :remote-method="getCompany" :loading="companyLoading">
                        <el-option v-for="item in companyData" :key="item.companyId" :label="item.companyName"
                            :value="item.companyId">
                        </el-option>
                    </el-select>
                    <i class="el-icon-search"></i>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate">提交申请</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="申请记录" :visible.sync="showTable"
            :destroy-on-close="true" width="80%" center>
            <el-tabs v-model="status" @tab-click="changeCompanyTable">
                <el-tab-pane label="待接受" :disabled="companyTableLoading" name="0">
                </el-tab-pane>
                <el-tab-pane label="已拒绝" :disabled="companyTableLoading" name="2">
                </el-tab-pane>
            </el-tabs>
            <commonTable :tableData="companyTableData" v-if="status == 0" :loading="companyTableLoading"
                @handleSizeChange="companyHandleSizeChange" @handleCurrentChange="companyHandleCurrentChange"
                :currentPage="companyCurrentPage" :total="companyTotal">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="targetName" align="center" label="公司名称" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>{{ scope.row.showName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" align="center" label="申请时间" show-overflow-tooltip />
                    <el-table-column prop="status" align="center" width="150" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag v-if="!scope.row.isTarget && scope.row.status == 0" type="info">待对方接受</el-tag>
                            <el-tag v-else-if="scope.row.status == 1">{{ statusData[scope.row.status] }}</el-tag>
                            <el-tag v-else type="danger">{{ statusData[scope.row.status] }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="120">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isTarget">
                                <el-button type="text" @click="updateStatusFun(scope.row, 1)">接受</el-button>
                                <el-button style="color: #F56C6C" type="text"
                                    @click="updateStatusFun(scope.row, 2)">拒绝</el-button>
                            </div>
                            <div v-else>
                                <el-button style="color: #F56C6C" type="text"
                                    @click="delApplyCompany(scope.row)">取消申请</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
            <commonTable :tableData="companyTableData" v-if="status == 2" :loading="companyTableLoading"
                @handleSizeChange="companyHandleSizeChange" @handleCurrentChange="companyHandleCurrentChange"
                :currentPage="companyCurrentPage" :total="companyTotal">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="targetName" align="center" label="公司名称" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>{{ scope.row.showName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" align="center" label="申请时间" show-overflow-tooltip />
                    <el-table-column prop="status" align="center" width="150" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == 0" type="info">{{ statusData[scope.row.status] }}</el-tag>
                            <el-tag v-else-if="scope.row.status == 1">{{ statusData[scope.row.status] }}</el-tag>
                            <el-tag v-else type="danger">{{ statusData[scope.row.status] }}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button style="color: #F56C6C" type="text"
                                v-if="userInfo.companyId == scope.row.companyId"
                                @click="delApplyCompany(scope.row)">删除记录</el-button>
                            <div v-else>
                                -
                            </div>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { setCompanyEachLink, getCompanyEachLinkList, getCompanyAllInfo, delCompanyEachLink } from "@/api/interflow";
// import customPopconfirm from "@/components/common/customPopconfirm";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        // customPopconfirm
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            statusData: {
                0: "待接受", 1: "已通过", 2: "已拒绝"
            },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {
                targetId: ""
            },
            companyData: [],
            companyLoading: false,
            rules: {},
            formType: "",
            updateId: 0,
            //审核消息
            status: "0",
            showTable: false,
            companyTableData: [],
            companyTableLoading: false,
            companyCurrentPage: 1,
            companyPageSize: 10,
            companyTotal: 0,
            statusNew: false,
        };
    },
    filters: {

    },
    created() {
        this.getList()
        this.getCompanyTable();
    },
    methods: {
        changeCompanyTable() {
            this.companyCurrentPage = 1;
            this.getCompanyTable();
        },
        /**@method 删除申请的互通公司 */
        delApplyCompany(row) {
            delCompanyEachLink({ linkId: row.linkId }).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "删除成功！"
                    })
                    this.getCompanyTable();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 修改互通状态 */
        updateStatusFun(row, status) {
            setCompanyEachLink({ linkId: row.linkId, status: status }).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "修改成功！"
                    })
                    if (status == 0) {
                        this.currentPage = 1;
                        this.getList();
                    }
                    this.companyCurrentPage = 1;
                    this.getCompanyTable();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 获取待通过/不通过的公司列表 */
        getCompanyTable() {
            let params = {
                pageSize: this.companyPageSize,
                pageNum: this.companyCurrentPage,
                status: Number(this.status),
                companyId: this.userInfo.companyId,
                showAll: 1
            };
            this.companyTableLoading = true;
            getCompanyEachLinkList(params).then(res => {
                this.companyTableLoading = false;
                if (res.data) {
                    const { data } = res;
                    this.companyTableData = data.pageInfo.list;
                    this.companyTotal = data.pageInfo.total;
                } else {
                    this.companyTableData = [];
                    this.companyTotal = 0;
                }
                if (this.status == 0) {
                    if (this.companyTableData.length > 0 && this.companyCurrentPage === 1) {//判断是否有申请数据
                        for (let row of this.companyTableData) {
                            if (row.isTarget) {
                                this.statusNew = true;
                                break;
                            }
                        }
                    } else if (this.companyTableData.length == 0 && this.companyCurrentPage === 1) {
                        this.statusNew = false;
                    }
                }
            }).catch(err => {
                this.companyTableLoading = false;
                this.companyTableData = [];
                this.companyTotal = 0;
            })
        },
        /**@method 切换行 */
        companyHandleSizeChange(val) {
            this.companyPageSize = val;
            this.getCompanyTable();
        },
        /**@method 切换下一页 */
        companyHandleCurrentChange(val) {
            this.companyCurrentPage = val;
            this.getCompanyTable();
        },
        /**@method 删除互通公司 */
        delcompany(row) {
            delCompanyEachLink({ linkId: row.linkId }).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "删除成功！"
                    })
                    this.getList();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 获取公司数据 */
        getCompany(companyName) {
            this.companyLoading = true;
            this.companyData = [];
            if (companyName) {
                getCompanyAllInfo({ companyName }).then(res => {
                    this.companyLoading = false;
                    if (res.data) {
                        this.companyData = res.data;
                    } else {
                        this.companyData = [];
                    }
                }).catch(err => {
                    this.companyLoading = false;
                    if (err.code === 201) {
                        this.$message.error(err.message)
                    }
                })
            } else {
                this.companyData = [];
            }
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    setCompanyEachLink(this.formData).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                type: "success",
                                message: "添加成功！"
                            })
                            this.companyCurrentPage = 1;
                            this.getCompanyTable();
                            this.showModel = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                status: 1,
                companyId: this.userInfo.companyId,
                showAll: 1
            };
            this.loading = true;
            getCompanyEachLinkList(params).then(res => {
                this.loading = false;
                if (res.data) {
                    const { data } = res;
                    this.tableData = data.pageInfo.list;
                    this.total = data.pageInfo.total;
                } else {
                    this.tableData = [];
                }
            }).catch(err => {
                this.loading = false;
                this.tableData = [];
                this.total = 0;
            })
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        showApplyList() {
            this.showTable = true;
            this.getCompanyTable();
        },
        /**@method 添加 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "合作伙伴";
            this.formType = "add";
            this.updateId = 0;
            this.formData = {
                targetId: ""
            };
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        // showUpdateModel(val) {
        //     this.showModel = true;
        //     this.modelTitle = "编辑...";
        //     this.formType = "update";
        //     this.updateId = val.id;
        //     this.formData = {

        //     };
        // },
    },
};
</script>
<style lang="scss">
.targetInput {
    width: 100%;

    .el-input--medium .el-input__inner {
        height: 45px;
        line-height: 45px;
    }
}
</style>
<style lang="scss" scoped>
.targetContent {
    position: relative;

    .el-icon-search {
        position: absolute;
        right: 20px;
        top: 12.5px;
        font-size: 20px;
    }
}

.avatarUrl {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.message {
    position: relative;

    .d {
        position: absolute;
        right: -2px;
        top: -2px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #ff3838;
    }
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>